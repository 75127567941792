<template>
  <div>
    <!-- Footer -->
    <footer id="footer">
      <div class="copyright">
        <span>
          &copy; American Smart Lending {{ new Date().getFullYear() }}
        </span>
        <span class="app-version">
          &emsp;&emsp;v2025.2.24.1
        </span>
        <div class="footer-text">
          <p>
            <router-link :to="{ name: 'disclosure'}">Disclosure</router-link>
            <span class="seperator">|</span>
            <router-link :to="{ name: 'contact'}">Licensing information</router-link>
            <span class="seperator">|</span>
            <router-link :to="{ name: 'resources'}">Resources</router-link>
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "AppFooter"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer-text p {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.footer-text .seperator {
  font-weight: bold;
  padding-left: 1rem;
  padding-right: 1rem;
}

#footer * {
  border-bottom: none;
}
</style>
