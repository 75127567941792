<template>
  <div ref="sectionFour" class="urla-section">
    <h2>Loan and Property Information</h2>

    <!-- Section description -->
    <p class="urla-section-description">
      This section asks about the loan's purpose and the property you want to
      purchase or refinance.
    </p>

    <!-- Required fields instruction -->
    <p class="instruction">
      Required fields are followed by
      <strong> <abbr class="required" title="required">*</abbr> </strong>.
    </p>

    <!-- Form for section 4 of URL Application -->
    <form
      id="section-4-form"
      v-on:submit.prevent="submitSectionFour"
      method="post"
    >
      <!-- part 4a. Loan and Property Information -->
      <div>
        <div>
          <div class="row">
            <div class="col-6 col-12-xsmall urla-left-section">
              <!-- Property Value -->
              <p>
                <label class="medium-label" for="property-value-">
                  <span>Sale price/Appraisal value:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <input
                  class="small-input"
                  id="property-value"
                  type="text"
                  v-model.lazy="
                    section4LoanAndPropertyInformation.part4aLoanInformation
                      .propertyValue
                  "
                  v-money="
                    section4LoanAndPropertyInformation.part4aLoanInformation
                      .propertyValue !== null
                      ? currencyFormat
                      : null
                  "
                  v-on:blur="validatePropertyValue($event)"
                  v-on:invalid="
                    validations.part4aLoanInformation.propertyValue = 0
                  "
                  required
                />
                <!-- Icon will be inserted below after validating the above input -->
                <validation-icon
                  v-bind:isValid="
                    validations.part4aLoanInformation.propertyValue
                  "
                ></validation-icon>
              </p>

              <!-- Loan Amount -->
              <p>
                <label class="medium-input" for="loan-amount">
                  <span>Loan Amount:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <input
                  class="small-input"
                  id="loan-amount"
                  type="text"
                  v-model.lazy="
                    section4LoanAndPropertyInformation.part4aLoanInformation
                      .loanAmount
                  "
                  v-money="
                    section4LoanAndPropertyInformation.part4aLoanInformation
                      .loanAmount !== null
                      ? currencyFormat
                      : null
                  "
                  v-on:blur="validateLoanAmount($event)"
                  v-on:invalid="
                    validations.part4aLoanInformation.loanAmount = 0
                  "
                  required
                />
                <!-- Icon will be inserted below after validating the above input -->
                <validation-icon
                  v-bind:isValid="validations.part4aLoanInformation.loanAmount"
                ></validation-icon>
              </p>

              <!-- preferred interest rate -->

              <!-- Loan Purpose -->
              <div>
                <p style="display: block">
                  <label
                    class="urla-radio-label medium-label"
                    for="loan-purpose"
                  >
                    <span>Loan Purpose?</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                </p>
                <fieldset
                  style="display: -webkit-flex;flex-direction: column;"
                  class="urla-radio radio-inline"
                >
                  <ul class="loan-purpose">
                    <li>
                      <input
                        name="loan-purpose"
                        type="radio"
                        id="purchase"
                        value="Purchase"
                        v-model="
                          section4LoanAndPropertyInformation
                            .part4aLoanInformation.loanPurpose
                        "
                        v-on:change="setLoansOnProperty"
                        required
                      />
                      <label
                        class="font-weight-normal small-label"
                        for="purchase"
                        >Purchase</label
                      >
                    </li>
                    <li>
                      <input
                        name="loan-purpose"
                        type="radio"
                        id="refinance"
                        value="Refinance"
                        v-model="
                          section4LoanAndPropertyInformation
                            .part4aLoanInformation.loanPurpose
                        "
                        v-on:change="setLoansOnProperty"
                      />
                      <label
                        class="font-weight-normal small-label"
                        for="refinance"
                        >Refinance</label
                      >
                    </li>
                    
                    <li>
                      <input
                        name="loan-purpose"
                        type="radio"
                        id="pre-approval-letter"
                        value="Pre-approval letter"
                        v-model="
                          section4LoanAndPropertyInformation
                            .part4aLoanInformation.loanPurpose
                        "
                        v-on:change="setLoansOnProperty"
                      />
                      <label
                        class="font-weight-normal small-label"
                        for="pre-approval-letter"
                        >Pre-approval letter</label
                      >
                    </li>
                  </ul>
                  <ul class="loan-purpose">
                    <li>
                      <input
                        name="loan-purpose"
                        type="radio"
                        id="commercial-purchase-self"
                        value="Commercial Purchase for self use"
                        v-model="
                          section4LoanAndPropertyInformation
                            .part4aLoanInformation.loanPurpose
                        "
                        v-on:change="setLoansOnProperty"
                        required
                      />
                      <label
                        class="font-weight-normal small-label"
                        for="commercial-purchase-self"
                        >Commercial Purchase (for self use)</label
                      >
                    </li>
                    <li>
                      <input
                        name="loan-purpose"
                        type="radio"
                        id="commercial-purchase-investment"
                        value="Commercial Purchase for investment"
                        v-model="
                          section4LoanAndPropertyInformation
                            .part4aLoanInformation.loanPurpose
                        "
                        v-on:change="setLoansOnProperty"
                        required
                      />
                      <label
                        class="font-weight-normal small-label"
                        for="commercial-purchase-investment"
                        >Commercial Purchase (for investment)</label
                      >
                    </li>
                  </ul>
                </fieldset>
              </div>

              <!-- loan program & Occupancy -->
              <div class="row">
                <!-- Preferred loan program -->
                <div class="col-4 col-12-xsmall">
                  <p>
                    <label
                      class="urla-radio-label"
                      for="preferred-loan-program"
                    >
                      <span>Preferred loan program:</span>
                      <strong>
                        <abbr class="required" title="required">*</abbr>
                      </strong>
                    </label>
                  </p>
                  <fieldset class="urla-radio">
                    <ul>
                      <li>
                        <input
                          name="preferred-loan-program"
                          type="radio"
                          id="fixed-30"
                          value="30-Year Fixed"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation.preferredLoanProgram
                          "
                          required
                        />
                        <label class="font-weight-normal" for="fixed-30"
                          >30-Year Fixed</label
                        >
                      </li>
                      <li>
                        <input
                          name="preferred-loan-program"
                          type="radio"
                          id="fixed-20"
                          value="20-Year Fixed"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation.preferredLoanProgram
                          "
                        />
                        <label class="font-weight-normal" for="fixed-20"
                          >20-Year Fixed</label
                        >
                      </li>
                      <li>
                        <input
                          name="preferred-loan-program"
                          type="radio"
                          id="fixed-15"
                          value="15-Year Fixed"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation.preferredLoanProgram
                          "
                        />
                        <label class="font-weight-normal" for="fixed-15"
                          >15-Year Fixed</label
                        >
                      </li>
                      <li>
                        <input
                          name="preferred-loan-program"
                          type="radio"
                          id="arm-7"
                          value="7-Year ARM"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation.preferredLoanProgram
                          "
                        />
                        <label class="font-weight-normal" for="arm-7"
                          >7-Year ARM</label
                        >
                      </li>
                    </ul>
                  </fieldset>
                </div>

                <div class="col-1 urla-left-section"></div>
                <div class="col-1"></div>

                <!-- Occupancy -->
                <div class="col-6 col-12-xsmall">
                  <p>
                    <label class="urla-radio-label" for="occupancy">
                      <span>Occupancy:</span>
                      <strong>
                        <abbr class="required" title="required">*</abbr>
                      </strong>
                    </label>
                  </p>
                  <fieldset class="urla-radio">
                    <ul>
                      <li>
                        <input
                          name="occupancy"
                          type="radio"
                          id="primary-residence"
                          value="Primary Residence"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation.occupancy
                          "
                          required
                        />
                        <label
                          class="font-weight-normal"
                          for="primary-residence"
                          >Primary Residence</label
                        >
                      </li>
                      <li>
                        <input
                          name="occupancy"
                          type="radio"
                          id="second-home"
                          value="Second Home"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation.occupancy
                          "
                        />
                        <label class="font-weight-normal" for="second-home"
                          >Second Home</label
                        >
                      </li>
                      <li>
                        <input
                          name="occupancy"
                          type="radio"
                          id="investment-property"
                          value="Investment Property"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation.occupancy
                          "
                        />
                        <label
                          class="font-weight-normal"
                          for="investment-property"
                          >Investment Property</label
                        >
                      </li>
                      <li>
                        <input
                          name="occupancy"
                          type="radio"
                          id="fha-secondary-residence"
                          value="FHA Secondary Residence"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation.occupancy
                          "
                        />
                        <label
                          class="font-weight-normal"
                          for="fha-secondary-residence"
                          >FHA Secondary Residence</label
                        >
                      </li>
                      <li>
                        <input
                          name="occupancy"
                          type="radio"
                          id="commercial-space"
                          value="Commercial Space"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation.occupancy
                          "
                        />
                        <label
                          class="font-weight-normal"
                          for="commercial-space"
                          >Commercial Space</label
                        >
                      </li>
                    </ul>
                  </fieldset>
                </div>
              </div>

              <!-- Type Of House  -->
              <div class="question-styling">
                <p style="display: block">
                  <label
                    class="urla-radio-label medium-label"
                    for="type-of-house"
                  >
                    <span>Type of house:</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                </p>
                <fieldset
                  style="display: inline"
                  class="urla-radio radio-inline"
                >
                  <ul class="type-of-house">
                    <li style="display:block">
                      <input
                        name="type-of-house"
                        type="radio"
                        id="Condo"
                        value="Condo"
                        v-model="
                          section4LoanAndPropertyInformation
                            .part4aLoanInformation.typeOfHouse
                        "
                        required
                      />
                      <label class="font-weight-normal small-label" for="Condo"
                        >Condo</label
                      >
                    </li>
                    <li style="display:block">
                      <input
                        name="type-of-house"
                        type="radio"
                        id="Townhome"
                        value="Townhome"
                        v-model="
                          section4LoanAndPropertyInformation
                            .part4aLoanInformation.typeOfHouse
                        "
                        required
                      />
                      <label
                        class="font-weight-normal small-label"
                        for="Townhome"
                        >Townhome</label
                      >
                    </li>
                    <li style="display:block">
                      <input
                        name="type-of-house"
                        type="radio"
                        id="Single-family-home"
                        value="Single-family home"
                        v-model="
                          section4LoanAndPropertyInformation
                            .part4aLoanInformation.typeOfHouse
                        "
                        required
                      />
                      <label
                        class="font-weight-normal small-label"
                        style="white-space:nowrap"
                        for="Single-family-home"
                        >Single-family home</label
                      >
                    </li>
                  </ul>
                </fieldset>
              </div>

              <!-- Manufactured Home? -->
              <div>
                <p style="line-height: 25px; padding-bottom:0;">
                  <label style="width:auto" for="manufactured-home">
                    <span>
                      <span class="font-weight-normal">Is the property a</span>
                      Manufactured Home?
                    </span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                    <br />
                    <span class="font-weight-normal font-style-italic"
                      >(e.g., a factory built dwelling built on a permanent
                      chasis)</span
                    >
                  </label>
                </p>
                <fieldset class="urla-radio radio-inline">
                  <ul>
                    <li>
                      <input
                        name="manufactured-home"
                        type="radio"
                        id="manufactured-home-yes"
                        v-bind:value="true"
                        v-model="
                          section4LoanAndPropertyInformation
                            .part4aLoanInformation.isManufacturedHome
                        "
                      />
                      <label
                        class="font-weight-normal small-label"
                        for="manufactured-home-yes"
                        >Yes</label
                      >
                    </li>
                    <li>
                      <input
                        name="manufactured-home"
                        type="radio"
                        id="manufactured-home-no"
                        v-bind:value="false"
                        v-model="
                          section4LoanAndPropertyInformation
                            .part4aLoanInformation.isManufacturedHome
                        "
                        required
                      />
                      <label
                        class="font-weight-normal small-label"
                        for="manufactured-home-no"
                        >No</label
                      >
                    </li>
                  </ul>
                </fieldset>
              </div>

              <!-- Refinancing current address? -->
              <transition name="fade">
                <div
                  v-if="
                    section4LoanAndPropertyInformation.part4aLoanInformation
                      .loanPurpose === 'Refinance'
                  "
                >
                  <div class="question-styling">
                    <p>
                      <label
                        class="urla-radio-label large-label"
                        for="refinance-current-address"
                      >
                        <span>Are you refinancing your current address?</span>
                        <strong>
                          <abbr class="required" title="required">*</abbr>
                        </strong>
                      </label>
                    </p>
                    <fieldset
                      style="display: inline"
                      class="urla-radio radio-inline"
                    >
                      <ul>
                        <li>
                          <input
                            name="refinance-current-address"
                            type="radio"
                            id="refinance-current-address-yes"
                            v-bind:value="true"
                            v-model="
                              section4LoanAndPropertyInformation
                                .part4aLoanInformation.refinanceCurrentAddress
                            "
                            v-on:change="prefillPropertyAddress"
                            required
                          />
                          <label
                            class="font-weight-normal small-label"
                            for="refinance-current-address-yes"
                            >Yes</label
                          >
                        </li>
                        <li>
                          <input
                            name="refinance-current-address"
                            type="radio"
                            id="refinance-current-address-no"
                            v-bind:value="false"
                            v-model="
                              section4LoanAndPropertyInformation
                                .part4aLoanInformation.refinanceCurrentAddress
                            "
                            v-on:change="prefillPropertyAddress"
                            required
                          />
                          <label
                            class="font-weight-normal small-label"
                            for="refinance-current-address-no"
                            >No</label
                          >
                        </li>
                      </ul>
                    </fieldset>
                  </div>
                </div>
              </transition>
            </div>

            <div class="col-6 col-12-xsmall">
              <!-- Property held on trust  -->
              <div class="question-styling">
                <p style="display: inline">
                  <label
                    class="urla-radio-label"
                    for="isPropertyHeldInATrust"
                    style="width:100%"
                  >
                    <span>Is this property held in a trust?</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                </p>
                <fieldset
                  style="display: inline"
                  class="urla-radio radio-inline"
                >
                  <ul class="type-of-house">
                    <li>
                      <input
                        name="isPropertyHeldInATrust"
                        type="radio"
                        id="isPropertyHeldInATrust-true"
                        :value="true"
                        v-model="
                          section4LoanAndPropertyInformation
                            .part4aLoanInformation.isPropertyHeldInATrust
                        "
                        required
                      />
                      <label
                        class="font-weight-normal small-label"
                        for="isPropertyHeldInATrust-true"
                        >Yes</label
                      >
                    </li>
                    <li>
                      <input
                        name="isPropertyHeldInATrust"
                        type="radio"
                        id="isPropertyHeldInATrust-false"
                        :value="false"
                        v-model="
                          section4LoanAndPropertyInformation
                            .part4aLoanInformation.isPropertyHeldInATrust
                        "
                        required
                      />
                      <label
                        class="font-weight-normal small-label"
                        for="isPropertyHeldInATrust-false"
                        >No</label
                      >
                    </li>
                  </ul>
                </fieldset>
              </div>

              <!-- Solar Panels  -->
              <div class="question-styling">
                <p style="display: inline">
                  <label
                    class="urla-radio-label"
                    for="hasSolarPanels"
                    style="width:100%"
                  >
                    <span>Are there solar panels on the house?</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                </p>
                <fieldset
                  style="display: inline"
                  class="urla-radio radio-inline"
                >
                  <ul class="type-of-house">
                    <li>
                      <input
                        name="hasSolarPanels"
                        type="radio"
                        id="hasSolarPanels-true"
                        :value="true"
                        v-model="
                          section4LoanAndPropertyInformation
                            .part4aLoanInformation.hasSolarPanels
                        "
                        required
                      />
                      <label
                        class="font-weight-normal small-label"
                        for="hasSolarPanels-true"
                        >Yes</label
                      >
                    </li>
                    <li>
                      <input
                        name="hasSolarPanels"
                        type="radio"
                        id="hasSolarPanels-false"
                        :value="false"
                        v-model="
                          section4LoanAndPropertyInformation
                            .part4aLoanInformation.hasSolarPanels
                        "
                        required
                      />
                      <label
                        class="font-weight-normal small-label"
                        for="hasSolarPanels-false"
                        >No</label
                      >
                    </li>
                  </ul>
                </fieldset>
              </div>

              <!-- Is condo without shared walls  -->
              <div class="question-styling">
                <p style="display: inline">
                  <label
                    class="urla-radio-label"
                    style="width:100%"
                    for="isCondoWithoutSharedWalls"
                  >
                    <span
                      >Is this property organized as a condo, even though it
                      does not share walls?</span
                    >
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                </p>
                <fieldset
                  style="display: inline"
                  class="urla-radio radio-inline"
                >
                  <ul class="type-of-house">
                    <li>
                      <input
                        name="isCondoWithoutSharedWalls"
                        type="radio"
                        id="Condo-true"
                        :value="true"
                        v-model="
                          section4LoanAndPropertyInformation
                            .part4aLoanInformation.isCondoWithoutSharedWalls
                        "
                        required
                      />
                      <label
                        class="font-weight-normal small-label"
                        for="Condo-true"
                        >Yes</label
                      >
                    </li>
                    <li>
                      <input
                        name="isCondoWithoutSharedWalls"
                        type="radio"
                        id="Condo-false"
                        :value="false"
                        v-model="
                          section4LoanAndPropertyInformation
                            .part4aLoanInformation.isCondoWithoutSharedWalls
                        "
                        required
                      />
                      <label
                        class="font-weight-normal small-label"
                        for="Condo-false"
                        >No</label
                      >
                    </li>
                  </ul>
                </fieldset>
              </div>

              <!-- City -->
              <!-- travelling outside city -->
              <div>
                <p style="display: inline">
                  <label
                    class="urla-radio-label"
                    for="isTravellingDuringClosing"
                    style="width:100%"
                  >
                    <span
                      >Will you be traveling outside of the city, where the
                      subject property is located, during closing ?</span
                    >
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                </p>
                <fieldset
                  style="display: inline"
                  class="urla-radio radio-inline"
                >
                  <ul class="type-of-house">
                    <li>
                      <input
                        name="isTravellingDuringClosing"
                        type="radio"
                        id="isTravellingDuringClosing-true"
                        :value="true"
                        v-model="
                          section4LoanAndPropertyInformation
                            .part4eAdditionalLoanInformation
                            .isTravellingDuringClosing
                        "
                        required
                      />
                      <label
                        class="font-weight-normal small-label"
                        for="isTravellingDuringClosing-true"
                        >Yes</label
                      >
                    </li>
                    <li>
                      <input
                        name="isTravellingDuringClosing"
                        type="radio"
                        id="isTravellingDuringClosing-false"
                        :value="false"
                        v-model="
                          section4LoanAndPropertyInformation
                            .part4eAdditionalLoanInformation
                            .isTravellingDuringClosing
                        "
                        required
                      />
                      <label
                        class="font-weight-normal small-label"
                        for="isTravellingDuringClosing-false"
                        >No</label
                      >
                    </li>
                  </ul>
                </fieldset>
              </div>

              <!-- Purchasing from Builder/Individual -->
              <transition name="fade">
                <div
                  v-if="
                    section4LoanAndPropertyInformation.part4aLoanInformation
                      .loanPurpose == 'Purchase' ||
                    
                    section4LoanAndPropertyInformation.part4aLoanInformation
                      .loanPurpose == 'Commercial Purchase for self use' ||

                    section4LoanAndPropertyInformation.part4aLoanInformation
                      .loanPurpose == 'Commercial Purchase for investment'
                  "
                >
                  <p>
                    <label class="urla-radio-label" for="builder-or-individual">
                      <span>I am purchasing the house from</span>
                      <strong>
                        <abbr class="required" title="required">*</abbr>
                      </strong>
                    </label>
                  </p>
                  <fieldset
                    style="display: inline"
                    class="urla-radio radio-inline"
                  >
                    <ul class="builder-or-individual">
                      <li>
                        <input
                          name="builder-or-individual"
                          type="radio"
                          id="individualSeller"
                          v-bind:value="false"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation.isPurchasingFromBuilder
                          "
                          v-on:change="setLoansOnProperty"
                          required
                        />
                        <label
                          class="font-weight-normal medium-label"
                          for="individualSeller"
                          >Individual Seller</label
                        >
                      </li>
                      <li>
                        <input
                          name="builder-or-individual"
                          type="radio"
                          id="builder"
                          v-bind:value="true"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation.isPurchasingFromBuilder
                          "
                          v-on:change="setLoansOnProperty"
                        />
                        <label
                          class="font-weight-normal medium-label"
                          for="builder"
                          >Builder</label
                        >
                      </li>
                    </ul>
                    <!-- Builder Name -->
                    <transition name="fade">
                      <p
                        v-if="
                          section4LoanAndPropertyInformation
                            .part4aLoanInformation.isPurchasingFromBuilder ==
                            true
                        "
                      >
                        <label class="medium-label" for="builderName">
                          <span>Builder Name:</span>
                          <strong>
                            <abbr class="required" title="required">*</abbr>
                          </strong>
                        </label>
                        <input
                          required
                          type="text"
                          class="medium-input"
                          id="builderName"
                          placeholder="Builder Name"
                          v-on:input="validateBuilderName($event)"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation.builderName
                          "
                        />
                        <validation-icon
                          v-bind:isValid="
                            validations.part4aLoanInformation.builderName
                          "
                        ></validation-icon>
                      </p>
                    </transition>
                  </fieldset>
                  <div>
                    <!-- Date of Birth -->
                    <p>
                      <label for="closingDate" class="medium-label">
                        <span>Closing Date (appx.):</span>
                        <strong>
                          <abbr class="required" title="required">*</abbr>
                        </strong>
                      </label>
                      <date-pick
                        id="lock-expires"
                        class="date-pick medium-input"
                        v-bind:inputAttributes="{
                          placeholder: 'MM/DD/YYYY',
                          required: 'required',
                        }"
                        required
                        v-model="
                          section4LoanAndPropertyInformation
                            .part4aLoanInformation.plannedClosingDate
                        "
                        v-bind:isDateDisabled="isPastDate"
                        v-bind:displayFormat="'MM/DD/YYYY'"
                      ></date-pick>
                    </p>
                  </div>
                  <div>
                    <p>
                      <label
                        class="urla-radio-label"
                        for="loan-purchase"
                        style="width:100%"
                      >
                        <span
                          >Is your realtor providing any credit towards the
                          closing cost?</span
                        >
                        <strong>
                          <abbr class="required" title="required">*</abbr>
                        </strong>
                      </label>
                    </p>
                    <fieldset
                      style="display: inline"
                      class="urla-radio radio-inline"
                    >
                      <ul class="loan-purchase">
                        <li>
                          <input
                            name="hasRealEstateCommission"
                            type="radio"
                            id="hasRealEstateCommission-true"
                            v-bind:value="true"
                            v-model="
                              section4LoanAndPropertyInformation
                                .part4eAdditionalLoanInformation
                                .hasRealEstateCommission
                            "
                            required
                          />
                          <label
                            class="font-weight-normal medium-label"
                            for="hasRealEstateCommission-true"
                            >Yes</label
                          >
                        </li>
                        <li>
                          <input
                            name="hasRealEstateCommission"
                            type="radio"
                            id="hasRealEstateCommission-false"
                            v-bind:value="false"
                            v-model="
                              section4LoanAndPropertyInformation
                                .part4eAdditionalLoanInformation
                                .hasRealEstateCommission
                            "
                            required
                          />
                          <label
                            class="font-weight-normal medium-label"
                            for="hasRealEstateCommission-false"
                            >No</label
                          >
                        </li>
                      </ul>
                    </fieldset>
                  </div>
                </div>
              </transition>

              <!-- Was this property refinanced before?  -->
              <transition name="fade">
                <div
                  v-if="
                    section4LoanAndPropertyInformation.part4aLoanInformation
                      .loanPurpose == 'Refinance'
                  "
                  class="question-styling"
                >
                  <p style="display: inline">
                    <label
                      class="urla-radio-label"
                      for="isRefinancedBefore"
                      style="width:100%"
                    >
                      <span>Was this property refinanced before?</span>
                      <strong>
                        <abbr class="required" title="required">*</abbr>
                      </strong>
                    </label>
                  </p>
                  <fieldset
                    style="display: inline"
                    class="urla-radio radio-inline"
                  >
                    <ul class="type-of-house">
                      <li>
                        <input
                          name="isRefinancedBefore"
                          type="radio"
                          id="isRefinancedBefore-true"
                          :value="true"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation.isRefinancedBefore
                          "
                          required
                        />
                        <label
                          class="font-weight-normal small-label"
                          for="isRefinancedBefore-true"
                          >Yes</label
                        >
                      </li>
                      <li>
                        <input
                          name="isRefinancedBefore"
                          type="radio"
                          id="isRefinancedBefore-false"
                          :value="false"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation.isRefinancedBefore
                          "
                          required
                        />
                        <label
                          class="font-weight-normal small-label"
                          for="isRefinancedBefore-false"
                          >No</label
                        >
                      </li>
                    </ul>
                  </fieldset>
                </div>
              </transition>

              <!-- when was the last time this property was refinanced? -->
              <transition name="fade">
                <div
                  v-if="
                    section4LoanAndPropertyInformation.part4aLoanInformation
                      .isRefinancedBefore == true &&
                      section4LoanAndPropertyInformation.part4aLoanInformation
                        .loanPurpose == 'Refinance'
                  "
                  class="question-styling"
                >
                  <p style="display: inline">
                    <label
                      class="urla-radio-label"
                      for="lastRefinanceDate"
                      style="width:100%"
                    >
                      <span
                        >When was the last time this property was
                        refinanced?</span
                      >
                      <strong>
                        <abbr class="required" title="required">*</abbr>
                      </strong>
                    </label>
                  </p>
                  <fieldset
                    style="display: inline"
                    class="urla-radio radio-inline"
                  >
                    <ul class="type-of-house">
                      <li>
                        <date-pick
                          id="lastRefinanceDate"
                          class="date-pick medium-input"
                          v-mask="'##/##/####'"
                          v-bind:inputAttributes="{
                            placeholder: 'MM/DD/YYYY',
                            required: 'required',
                          }"
                          v-bind:isDateDisabled="isFutureDate"
                          v-bind:displayFormat="'MM/DD/YYYY'"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation.lastRefinanceDate
                          "
                        ></date-pick>
                      </li>
                    </ul>
                  </fieldset>
                </div>
              </transition>

              <!-- Did you ever take a cash-out loan on this property?  -->
              <transition name="fade">
                <div
                  v-if="
                    section4LoanAndPropertyInformation.part4aLoanInformation
                      .isRefinancedBefore == true &&
                      section4LoanAndPropertyInformation.part4aLoanInformation
                        .loanPurpose == 'Refinance'
                  "
                  class="question-styling"
                >
                  <p style="display: inline">
                    <label
                      class="urla-radio-label"
                      for="hasTakenHomeEquityLineOfCredit"
                      style="width:100%"
                    >
                      <span>Did you ever take a cash-out loan on this property?</span>
                      <strong>
                        <abbr class="required" title="required">*</abbr>
                      </strong>
                    </label>
                  </p>
                  <fieldset
                    style="display: inline"
                    class="urla-radio radio-inline"
                  >
                    <ul class="type-of-house">
                      <li>
                        <input
                          name="hasTakenHomeEquityLineOfCredit"
                          type="radio"
                          id="hasTakenHomeEquityLineOfCredit-true"
                          :value="true"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation
                              .hasTakenHomeEquityLineOfCredit
                          "
                          required
                        />
                        <label
                          class="font-weight-normal small-label"
                          for="hasTakenHomeEquityLineOfCredit-true"
                          >Yes</label
                        >
                      </li>
                      <li>
                        <input
                          name="hasTakenHomeEquityLineOfCredit"
                          type="radio"
                          id="hasTakenHomeEquityLineOfCredit-false"
                          :value="false"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation
                              .hasTakenHomeEquityLineOfCredit
                          "
                          required
                        />
                        <label
                          class="font-weight-normal small-label"
                          for="hasTakenHomeEquityLineOfCredit-false"
                          >No</label
                        >
                      </li>
                    </ul>
                  </fieldset>
                </div>
              </transition>

              <!-- Is Spouse Available During Closing -->
              <transition name="fade">
                <div
                  v-if="
                    section4LoanAndPropertyInformation.part4aLoanInformation
                      .occupancy == 'Primary Residence'
                  "
                  class="question-styling"
                >
                  <p>
                    <label
                      class="urla-radio-label"
                      for="occupancy"
                      style="width:100%"
                    >
                      <span
                        >Is your spouse available during closing, since this is
                        a primary residence?</span
                      >
                      <strong>
                        <abbr class="required" title="required">*</abbr>
                      </strong>
                    </label>
                  </p>
                  <fieldset class="urla-radio">
                    <ul style="display:flex">
                      <li>
                        <input
                          name="isSpouseAvailableDuringClosing"
                          type="radio"
                          id="isSpouseAvailableDuringClosing-true"
                          :value="true"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4eAdditionalLoanInformation
                              .isSpouseAvailableDuringClosing
                          "
                        />
                        <label
                          class="font-weight-normal  small-label"
                          for="isSpouseAvailableDuringClosing-true"
                          >Yes</label
                        >
                      </li>
                      <li>
                        <input
                          name="isSpouseAvailableDuringClosing"
                          type="radio"
                          id="isSpouseAvailableDuringClosing-false"
                          :value="false"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4eAdditionalLoanInformation
                              .isSpouseAvailableDuringClosing
                          "
                          required
                        />
                        <label
                          class="font-weight-normal  small-label"
                          for="isSpouseAvailableDuringClosing-false"
                          >No</label
                        >
                      </li>
                    </ul>
                  </fieldset>
                </div>
              </transition>

              <!-- Mixed-Use Property? -->
              <transition name="fade">
                <div
                  v-if="
                    section4LoanAndPropertyInformation.part4aLoanInformation
                      .occupancy === 'Primary Residence'
                  "
                  class="question-styling"
                >
                  <p style="line-height: 25px; padding-bottom:0;">
                    <label style="display: inline" for="mixed-use-property">
                      <span>
                        Mixed-Use Property.
                        <span class="font-weight-normal"
                          >If you will occupy the property, will you set aside
                          space within the property to operate your own
                          business?</span
                        >
                      </span>
                      <strong>
                        <abbr class="required" title="required">*</abbr>
                      </strong>
                    </label>
                  </p>
                  <fieldset class="urla-radio radio-inline">
                    <ul>
                      <li>
                        <input
                          name="mixed-use-property"
                          type="radio"
                          id="mixed-use-property-yes"
                          v-bind:value="true"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation.isMixedUseProperty
                          "
                        />
                        <label
                          class="font-weight-normal small-label"
                          for="mixed-use-property-yes"
                          >Yes</label
                        >
                      </li>
                      <li>
                        <input
                          name="mixed-use-property"
                          type="radio"
                          id="mixed-use-property-no"
                          v-bind:value="false"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation.isMixedUseProperty
                          "
                          required
                        />
                        <label
                          class="font-weight-normal small-label"
                          for="mixed-use-property-no"
                          >No</label
                        >
                      </li>
                    </ul>
                  </fieldset>
                </div>
              </transition>

              <!-- Questions for Investment Property -->
              <div>
                <transition name="fade">
                  <div
                    v-if="
                      section4LoanAndPropertyInformation.part4aLoanInformation
                        .occupancy === 'Investment Property'
                    "
                  >
                    <!-- Purchased On -->
                    <transition name="fade">
                      <p
                        v-if="
                          section4LoanAndPropertyInformation
                            .part4aLoanInformation.loanPurpose === 'Refinance'
                        "
                      >
                        <label for="purchased-on" class="medium-label">
                          <span>Purchased On:</span>
                          <strong>
                            <abbr class="required" title="required">*</abbr>
                          </strong>
                        </label>
                        <date-pick
                          id="purchased-on"
                          class="date-pick medium-input"
                          v-mask="'##/##/####'"
                          v-bind:inputAttributes="{
                            placeholder: 'MM/DD/YYYY',
                            required: 'required',
                          }"
                          v-bind:isDateDisabled="isFutureDate"
                          v-bind:displayFormat="'MM/DD/YYYY'"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation.purchasedOn
                          "
                          v-on:input="validatePurchasedOn($event)"
                          v-on:invalid="
                            validations.part4aLoanInformation.purchasedOn = 0
                          "
                        ></date-pick>
                        <!-- Icon will be inserted below after validating the above input -->
                        <validation-icon
                          v-bind:isValid="
                            validations.part4aLoanInformation.purchasedOn
                          "
                        ></validation-icon>
                      </p>
                    </transition>
                    <!-- h4>Rental Income on the property You Want to Purchase</h4 -->
                    <!-- Expected Monthly Rental Income -->
                    <transition name="fade">
                      <p>
                        <label
                          for="expected-monthly-rental-income"
                          style="width: 275px"
                        >
                          <span>
                            <span
                              v-if="
                                section4LoanAndPropertyInformation
                                  .part4aLoanInformation.loanPurpose ===
                                  'Purchase' ||
                                section4LoanAndPropertyInformation
                                  .part4aLoanInformation.loanPurpose ===
                                  'Commercial Purchase for investment' ||
                                section4LoanAndPropertyInformation
                                  .part4aLoanInformation.loanPurpose ===
                                  'Commercial Purchase for self use'
                              "
                              >Expected</span
                            >
                            Monthly Rental Income:
                          </span>
                          <strong>
                            <abbr class="required" title="required">*</abbr>
                          </strong>
                        </label>
                        <input
                          class="small-input"
                          id="expected-monthly-rental-income"
                          type="text"
                          v-model.lazy="
                            section4LoanAndPropertyInformation
                              .part4cRentalIncomeOnSubjectProperty
                              .expectedMonthlyRentalIncome
                          "
                          v-money="
                            section4LoanAndPropertyInformation
                              .part4cRentalIncomeOnSubjectProperty
                              .expectedMonthlyRentalIncome !== null
                              ? currencyFormat
                              : null
                          "
                          v-on:blur="
                            validateExpectedMonthlyRentalIncome($event)
                          "
                          v-on:invalid="
                            validations.part4cRentalIncomeOnSubjectProperty.expectedMonthlyRentalIncome = 0
                          "
                          required
                        />
                        <!-- Icon will be inserted below after validating the above input -->
                        <validation-icon
                          v-bind:isValid="
                            validations.part4cRentalIncomeOnSubjectProperty
                              .expectedMonthlyRentalIncome
                          "
                        ></validation-icon>
                      </p>
                    </transition>

                    <!-- Investment property since -->
                    <transition name="fade">
                      <p
                        v-if="
                          section4LoanAndPropertyInformation
                            .part4aLoanInformation.loanPurpose === 'Refinance'
                        "
                      >
                        <label
                          for="investment-property-since"
                          style="width: 275px"
                        >
                          <span>Investment Property since:</span>
                          <strong>
                            <abbr class="required" title="required">*</abbr>
                          </strong>
                        </label>
                        <date-pick
                          id="investment-property-since"
                          class="date-pick small-input"
                          v-mask="'##/##/####'"
                          v-bind:inputAttributes="{
                            placeholder: 'MM/DD/YYYY',
                            required: 'required',
                          }"
                          v-bind:isDateDisabled="isFutureDate"
                          v-bind:displayFormat="'MM/DD/YYYY'"
                          v-model="
                            section4LoanAndPropertyInformation
                              .part4aLoanInformation.investmentPropertySince
                          "
                          v-on:input="validateInvestmentPropertySince($event)"
                          v-on:invalid="
                            validations.part4aLoanInformation.investmentPropertySince = 0
                          "
                        ></date-pick>
                        <!-- Icon will be inserted below after validating the above input -->
                        <validation-icon
                          v-bind:isValid="
                            validations.part4aLoanInformation
                              .investmentPropertySince
                          "
                        ></validation-icon>
                      </p>
                    </transition>
                  </div>
                </transition>
              </div>
            </div>
          </div>

          <transition name="fade">
            <div
              v-if="
                section4LoanAndPropertyInformation.part4aLoanInformation
                  .loanPurpose !== null
              "
            >
              <!-- Property Address -->
              <div class="row">
                <h5 class="col-12 col-12-xsmall">Property Address</h5>
                <div class="col-6 col-12-xsmall urla-left-section">
                  <!-- Street -->
                  <p>
                    <label class="small-label" for="street">
                      <span>Street:</span>
                      <strong>
                        <abbr class="required" title="required">*</abbr>
                      </strong>
                    </label>
                    <input
                      class="medium-input"
                      id="street"
                      type="text"
                      placeholder="Street"
                      v-model="
                        section4LoanAndPropertyInformation.part4aLoanInformation
                          .propertyAddress.street
                      "
                      v-on:input="validateStreet($event)"
                      v-on:invalid="
                        validations.part4aLoanInformation.propertyAddress.street = 0
                      "
                      required
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      v-bind:isValid="
                        validations.part4aLoanInformation.propertyAddress.street
                      "
                    ></validation-icon>
                  </p>

                  <!-- Unit -->
                  <p>
                    <label class="medium-label" for="unit">Unit:</label>
                    <input
                      class="small-input"
                      id="unit"
                      type="text"
                      placeholder="unit"
                      v-model="
                        section4LoanAndPropertyInformation.part4aLoanInformation
                          .propertyAddress.unit
                      "
                      v-on:change="validateUnit($event)"
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      v-bind:isValid="
                        validations.part4aLoanInformation.propertyAddress.unit
                      "
                    ></validation-icon>
                  </p>

                  <!-- zip -->
                  <p>
                    <label class="medium-label" for="zip">
                      <span>Zip:</span>
                      <strong>
                        <abbr class="required" title="required">*</abbr>
                      </strong>
                    </label>
                    <input
                      class="small-input"
                      id="zip"
                      type="text"
                      minlength="5"
                      maxlength="5"
                      placeholder="00000"
                      v-model="
                        section4LoanAndPropertyInformation.part4aLoanInformation
                          .propertyAddress.zip
                      "
                      v-on:input="validateZip($event)"
                      v-on:invalid="
                        validations.part4aLoanInformation.propertyAddress.zip = 0
                      "
                      required
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      v-bind:isValid="
                        validations.part4aLoanInformation.propertyAddress.zip
                      "
                    ></validation-icon>
                  </p>

                  <!-- Number of Units -->
                  <p>
                    <label class="medium-label" for="number-of-units">
                      <span>Number of Units:</span>
                      <strong>
                        <abbr class="required" title="required">*</abbr>
                      </strong>
                    </label>
                    <input
                      class="small-input"
                      id="number-of-units"
                      type="number"
                      min="1"
                      max="3"
                      v-model.number="
                        section4LoanAndPropertyInformation.part4aLoanInformation
                          .propertyAddress.numberOfUnits
                      "
                      v-on:input="validateNumberOfUnits($event)"
                      v-on:invalid="
                        validations.part4aLoanInformation.propertyAddress.numberOfUnits = 0
                      "
                      required
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      v-bind:isValid="
                        validations.part4aLoanInformation.propertyAddress
                          .numberOfUnits
                      "
                    ></validation-icon>
                  </p>
                </div>

                <div class="col-6 col-12-xsmall">
                  <!-- City -->
                  <p>
                    <label class="small-label" for="city">
                      <span>City:</span>
                      <strong>
                        <abbr class="required" title="required">*</abbr>
                      </strong>
                    </label>
                    <input
                      class="medium-input"
                      id="city"
                      type="text"
                      placeholder="City"
                      v-model="
                        section4LoanAndPropertyInformation.part4aLoanInformation
                          .propertyAddress.city
                      "
                      v-on:input="validateCity($event)"
                      v-on:invalid="
                        validations.part4aLoanInformation.propertyAddress.city = 0
                      "
                      required
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      v-bind:isValid="
                        validations.part4aLoanInformation.propertyAddress.city
                      "
                    ></validation-icon>
                  </p>

                  <!-- State -->
                  <p>
                    <label class="small-label" for="state">
                      <span>State:</span>
                      <strong>
                        <abbr class="required" title="required">*</abbr>
                      </strong>
                    </label>
                    <input
                      class="medium-input"
                      id="state"
                      type="text"
                      placeholder="State"
                      v-model="
                        section4LoanAndPropertyInformation.part4aLoanInformation
                          .propertyAddress.state
                      "
                      v-on:input="validateState($event)"
                      v-on:invalid="
                        validations.part4aLoanInformation.propertyAddress.state = 0
                      "
                      required
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      v-bind:isValid="
                        validations.part4aLoanInformation.propertyAddress.state
                      "
                    ></validation-icon>
                  </p>

                  <!-- County -->
                  <p>
                    <label class="small-label" for="county">County:</label>
                    <input
                      class="medium-input"
                      id="county"
                      type="text"
                      placeholder="County"
                      v-model="
                        section4LoanAndPropertyInformation.part4aLoanInformation
                          .propertyAddress.county
                      "
                      v-on:change="validateCounty($event)"
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      v-bind:isValid="
                        validations.part4aLoanInformation.propertyAddress.county
                      "
                    ></validation-icon>
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col-6 col-12-xsmall urla-left-section">
                  <!-- Other loans on this property? -->
                  <!--transition name="fade">
                    <div
                      v-if="section4LoanAndPropertyInformation.part4aLoanInformation.loanPurpose==='Refinance'"
                    >
                      <p style="line-height: 25px; padding-bottom:0;">
                        <label style="width:auto" for="other-loans-on-property">
                          <span class="font-weight-normal">
                            Do you have any other
                            <span class="font-weight-bold">new Mortgage Loans</span> on the property you are Purchasing or Refinancing?
                          </span>
                          <strong>
                            <abbr class="required" title="required">*</abbr>
                          </strong>
                        </label>
                      </p>
                      <fieldset class="urla-radio radio-inline">
                        <ul>
                          <li>
                            <input
                              name="other-loans-on-property"
                              type="radio"
                              id="other-loans-on-property-no"
                              v-bind:value="false"
                              v-model="section4LoanAndPropertyInformation.part4aLoanInformation.hasOtherLoansOnProperty"
                              v-on:change="skipLoansOnProperty"
                              required
                            />
                            <label
                              class="font-weight-normal small-label"
                              for="other-loans-on-property-no"
                            >No</label>
                          </li>
                          <li>
                            <input
                              name="other-loans-on-property"
                              type="radio"
                              id="other-loans-on-property-yes"
                              v-bind:value="true"
                              v-model="section4LoanAndPropertyInformation.part4aLoanInformation.hasOtherLoansOnProperty"
                              v-on:change="skipLoansOnProperty"
                            />
                            <label
                              class="font-weight-normal small-label"
                              for="other-loans-on-property-yes"
                            >Yes</label>
                          </li>
                        </ul>
                      </fieldset>
                    </div>
                  </transition-->
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>

      <transition name="fade">
        <div
          v-if="
            this.section4LoanAndPropertyInformation
              .part4bOtherLoansOnSubjectProperty.length !== 0
          "
        >
          <hr class="urla-break" />
          <!-- part 4b. Other loans on subject property -->
          <div class="row">
            <div class="col-6 col-12-xsmall">
              <h4>Mortgage Loans on the property you are Refinancing</h4>
            </div>

            <transition-group name="list" tag="div">
              <div
                class="list-item-transition"
                v-for="(loan,
                index) in section4LoanAndPropertyInformation.part4bOtherLoansOnSubjectProperty"
                v-bind:key="index + 1"
              >
                <div class="item-action-wrapper row">
                  <!-- Loan Number -->
                  <h5 class="col-4 col-12-xsmall">
                    Mortgage Loan {{ index + 1 }}
                  </h5>

                  <!-- Remove loan on property button -->
                  <div class="col-8 col-12-xsmall">
                    <button
                      title="Remove Loan on Property"
                      v-bind:id="index + 1"
                      @click.prevent="removeLoanOnProperty(index)"
                      class="remove-button"
                      style="float:none"
                    >
                      <i class="fa fa-lg fa-times"></i> Delete Loan
                    </button>
                  </div>

                  <div class="row col-8 col-12-xsmall">
                    <!-- Creditor Name -->
                    <p class="col-6 col-12-xsmall">
                      <label
                        class="medium-label"
                        v-bind:for="'creditor-name-' + index"
                      >
                        <span>Creditor Name:</span>
                        <strong>
                          <abbr class="required" title="required">*</abbr>
                        </strong>
                      </label>
                      <input
                        class="small-input"
                        v-bind:id="'creditor-name-' + index"
                        type="text"
                        placeholder="Creditor"
                        v-model="loan.creditorName"
                        v-on:input="validateCreditorName($event, index)"
                        v-on:invalid="
                          validations.part4bOtherLoansOnSubjectProperty[
                            index
                          ].creditorName = 0
                        "
                        required
                      />
                      <!-- Icon will be inserted below after validating the above input -->
                      <validation-icon
                        v-bind:isValid="
                          validations.part4bOtherLoansOnSubjectProperty[index]
                            .creditorName
                        "
                      ></validation-icon>
                    </p>

                    <!-- Monthly Payment -->
                    <p class="col-6 col-12-xsmall urla-middle-section">
                      <label
                        class="medium-label"
                        v-bind:for="'monthly-payment' + index"
                      >
                        <span>Monthly Payment:</span>
                        <strong>
                          <abbr class="required" title="required">*</abbr>
                        </strong>
                      </label>
                      <input
                        class="small-input"
                        v-bind:id="'monthly-payment-' + index"
                        type="text"
                        v-model.lazy="loan.monthlyPayment"
                        v-money="
                          loan.monthlyPayment !== null ? currencyFormat : null
                        "
                        v-on:blur="validateMonthlyPayment($event, index)"
                        v-on:invalid="
                          validations.part4bOtherLoansOnSubjectProperty[
                            index
                          ].monthlyPayment = 0
                        "
                        required
                      />
                      <!-- Icon will be inserted below after validating the above input -->
                      <validation-icon
                        v-bind:isValid="
                          validations.part4bOtherLoansOnSubjectProperty[index]
                            .monthlyPayment
                        "
                      ></validation-icon>
                    </p>

                    <!-- Loan Amount / Amount to be Drawn -->
                    <p class="col-6 col-12-xsmall">
                      <label
                        class="medium-label"
                        v-bind:for="'loan-amount-' + index"
                      >
                        <span>Loan Amount:</span>
                        <strong>
                          <abbr class="required" title="required">*</abbr>
                        </strong>
                      </label>
                      <input
                        class="small-input"
                        v-bind:id="'loan-amount-' + index"
                        type="text"
                        v-model.lazy="loan.loanAmount"
                        v-money="
                          loan.loanAmount !== null ? currencyFormat : null
                        "
                        v-on:blur="validateOtherLoanAmount($event, index)"
                        v-on:invalid="
                          validations.part4bOtherLoansOnSubjectProperty[
                            index
                          ].loanAmount = 0
                        "
                        required
                      />
                      <!-- Icon will be inserted below after validating the above input -->
                      <validation-icon
                        v-bind:isValid="
                          validations.part4bOtherLoansOnSubjectProperty[index]
                            .loanAmount
                        "
                      ></validation-icon>
                    </p>

                    <!-- Credit Limit -->
                    <p class="col-6 col-12-xsmall urla-middle-section">
                      <label class="medium-label" v-bind:for="'credit-limit-'">
                        <span>Credit Limit:</span>
                      </label>
                      <input
                        class="small-input"
                        v-bind:id="'credit-limit-' + index"
                        type="text"
                        v-model.lazy="loan.creditLimit"
                        v-money="
                          loan.creditLimit !== null ? currencyFormat : null
                        "
                      />
                    </p>
                  </div>

                  <!-- Lien Type -->
                  <div class="col-4 col-12-xsmall">
                    <p>
                      <label
                        class="urla-radio-label small-label"
                        v-bind:for="'lien-type-' + index"
                      >
                        <span>Lien Type:</span>
                        <strong>
                          <abbr class="required" title="required">*</abbr>
                        </strong>
                      </label>
                    </p>
                    <fieldset
                      class="urla-radio radio-inline"
                      style="display: inline"
                    >
                      <ul>
                        <li>
                          <input
                            v-bind:name="'lien-type' + index"
                            type="radio"
                            v-bind:id="'first-lien-' + index"
                            value="First Lien"
                            v-model="loan.lienType"
                            required
                          />
                          <label
                            class="font-weight-normal small-label"
                            v-bind:for="'first-lien-' + index"
                            >First Lien</label
                          >
                        </li>
                        <li>
                          <input
                            v-bind:name="'lien-type' + index"
                            type="radio"
                            v-bind:id="'subordinate-lien-' + index"
                            value="Subordinate Lien"
                            v-model="loan.lienType"
                          />
                          <label
                            class="font-weight-normal medium-label"
                            v-bind:for="'subordinate-lien-' + index"
                            >Subordinate Lien</label
                          >
                        </li>
                      </ul>
                    </fieldset>

                    <!-- Cash out Loan fields -->
                    <p class="col-6 col-12-xsmall">
                      <label for="cash-out">Is Cash-Out loan?</label>
                    </p>
                    <fieldset
                      class="urla-radio radio-inline"
                      style="display: inline"
                    >
                      <ul>
                        <li>
                          <input
                            v-bind:name="'cash-out' + index"
                            type="radio"
                            v-bind:id="'cash-out-yes' + index"
                            :value="true"
                            v-model="loan.isCashOutLoan"
                            required
                          />
                          <label
                            class="font-weight-normal small-label"
                            v-bind:for="'cash-out-yes' + index"
                            >Yes</label
                          >
                        </li>
                        <li>
                          <input
                            v-bind:name="'cash-out' + index"
                            type="radio"
                            v-bind:id="'cash-out-no' + index"
                            :value="false"
                            v-model="loan.isCashOutLoan"
                            required
                          />
                          <label
                            class="font-weight-normal small-label"
                            v-bind:for="'cash-out-no' + index"
                            >No</label
                          >
                        </li>
                      </ul>
                    </fieldset>
                  </div>
                </div>
              </div>
            </transition-group>

            <!-- Add Loan on Property Button -->
            <div class="col-12 col-12-xsmall">
              <button
                @click.prevent="addLoanOnProperty()"
                class="button add-button"
                style="font-size: 13px"
              >
                <i class="fa fa-plus"></i> Loan
              </button>
            </div>
          </div>
        </div>
      </transition>

      <br />

      <!-- clearfix for back and save -->
      <div class="clearfix">
        <!-- back button -->
        <button
          type="button"
          class="primary wizard__back pull-left"
          @click.prevent="goBack()"
        >
          <span>
            <i class="fa fa-arrow-left fa-lg"></i>
            back
          </span>
        </button>
        <!-- save & continue button -->
        <button type="submit" class="primary wizard__next pull-right">
          <span>
            <i class="fa fa-floppy-o" aria-hidden="true"></i>
            save
            <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { bus } from "@/main";
import DatePick from "vue-date-pick";
import validationsMixin from "@/mixins/validationsMixin.js";
import inputMasksMixin from "@/mixins/inputMasksMixin.js";
import jsonUtilMixin from "@/mixins/jsonUtilMixin.js";
import zipCodeMixin from "@/mixins/zipCodeMixin.js";

export default {
  name: "Section4",
  // components used
  components: { "date-pick": DatePick },
  // date received from parent component
  props: ["sourceSection4"],
  // mixins used
  mixins: [validationsMixin, inputMasksMixin, jsonUtilMixin, zipCodeMixin],
  data() {
    return {
      currentDate: null,
      currencyFormat: {
        // input masking for currency
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 0,
      },
      section4LoanAndPropertyInformation: {
        loanApplicationProgress: 300,
        currentPage: 3,
        sectionName: "section4LoanAndPropertyInformation",
        part4aLoanInformation: {
          loanAmount: null,
          loanPurpose: null,
          preferredLoanProgram: null,
          preferredInterestRate: null,
          refinanceCurrentAddress: null,
          hasOtherLoansOnProperty: null,
          propertyAddress: {
            street: null,
            unit: null,
            city: null,
            state: null,
            zip: null,
            county: null,
            numberOfUnits: null,
          },
          propertyValue: null,
          purchasedOn: null,
          investmentPropertySince: null,
          occupancy: null,
          isMixedUseProperty: false,
          isManufacturedHome: false,
          isRefinancedBefore: null,
        },
        part4bOtherLoansOnSubjectProperty: [],
        part4cRentalIncomeOnSubjectProperty: {
          expectedMonthlyRentalIncome: null,
          expectedNetMonthlyRentalIncome: null,
        },
        part4eAdditionalLoanInformation: {
          hasRealEstateCommission: null,
          isSpouseAvailableDuringClosing: null,
          isTravellingDuringClosing: null,
        },
        expectedPropertyAddress: {
          addressType: null,
          chronologicalOrder: null,
          city: null,
          country: null,
          monthsAtAddress: null,
          numberOfUnits: null,
          state: null,
          street: null,
          unit: null,
          yearsAtAddress: null,
          zip: null,
        },
      },
      validations: null,
      errors: null,
      convertSalePrice: null,
      convertLoanAmount: null,
    };
  },
  methods: {
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },

    validateBuilderName: function(event) {
      // validate first name from validationsMixin
      this.errors.part4aLoanInformation.builderName = this.checkFirstName(
        event
      );
      // set validation-icon
      this.validations.part4aLoanInformation.builderName =
        this.errors.part4aLoanInformation.builderName.length === 0 ? 1 : 0;
    },

    getFormattedDate: function(date) {
      // extract day, month and year from date
      const dd = String(date.getDate()).padStart(2, "0");
      const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = date.getFullYear();
      // concat string to yyyy-mm-dd format
      const formattedDate = yyyy + "-" + mm + "-" + dd;
      // return formatted date
      return formattedDate;
    },

    getToday: function() {
      // create new Date
      const date = new Date();

      // return today
      return this.getFormattedDate(date);
    },

    prefillPropertyAddress: function() {
      // expected address
      const expected = this.section4LoanAndPropertyInformation
        .expectedPropertyAddress;
      // field for property address
      const address = this.section4LoanAndPropertyInformation
        .part4aLoanInformation.propertyAddress;

      // return from function if address is already provided
      if (
        this.section4LoanAndPropertyInformation.part4aLoanInformation
          .propertyAddress.street !== null
      )
        return;

      // check if refinanceCurrentAddress is true
      if (
        this.section4LoanAndPropertyInformation.part4aLoanInformation
          .refinanceCurrentAddress
      ) {
        // prefill address
        address.street = expected.street;
        address.unit = expected.unit;
        address.zip = expected.zip;
        address.city = expected.city;
        address.county = expected.county;
        address.state = expected.state;
      } else {
        // clear fields
        address.street = null;
        address.unit = null;
        address.zip = null;
        address.city = null;
        address.county = null;
        address.state = null;
      }
    },

    addLoanOnProperty: function() {
      // empty loan object
      const loan = {
        creditorName: null,
        // default lienType
        lienType: "First Lien",
        monthlyPayment: null,
        loanAmount: null,
        creditLimit: null,
      };

      // empty property object for error
      const loanError = {
        creditorName: [],
        lienType: [],
        monthlyPayment: [],
        loanAmount: [],
        creditLimit: [],
      };

      // add empty object to assets list
      this.section4LoanAndPropertyInformation.part4bOtherLoansOnSubjectProperty.push(
        JSON.parse(JSON.stringify(loan))
      );

      // add empty object to validations list
      this.validations.part4bOtherLoansOnSubjectProperty.push(
        JSON.parse(JSON.stringify(loan))
      );

      // add empty object to errors list
      this.errors.part4bOtherLoansOnSubjectProperty.push(
        JSON.parse(JSON.stringify(loanError))
      );

      // set the checkbox for "I do have any other loans on this property"
      if (
        this.section4LoanAndPropertyInformation
          .part4bOtherLoansOnSubjectProperty.length !== 0
      )
        this.section4LoanAndPropertyInformation.part4aLoanInformation.hasOtherLoansOnProperty = true;
    },

    removeLoanOnProperty: function(index) {
      // remove row from mortgageLoansOnThisProperty owned array
      this.section4LoanAndPropertyInformation.part4bOtherLoansOnSubjectProperty.splice(
        index,
        1
      );

      // remove row from validations array
      this.validations.part4bOtherLoansOnSubjectProperty.splice(index, 1);

      // remove row from errors array
      this.errors.part4bOtherLoansOnSubjectProperty.splice(index, 1);

      // set the checkbox for "I do have any other loans on this property"
      if (
        this.section4LoanAndPropertyInformation
          .part4bOtherLoansOnSubjectProperty.length === 0
      )
        this.section4LoanAndPropertyInformation.part4aLoanInformation.hasOtherLoansOnProperty = false;
    },

    removeAllLoansOnProperty: function() {
      // remove row from properties owned array
      this.section4LoanAndPropertyInformation.part4bOtherLoansOnSubjectProperty = [];

      // remove row from validations array
      this.validations.part4bOtherLoansOnSubjectProperty = [];

      // remove row from errors array
      this.errors.part4bOtherLoansOnSubjectProperty = [];

      // set flag for hasOtherLoansOnProperty
      this.section4LoanAndPropertyInformation.part4aLoanInformation.hasOtherLoansOnProperty = false;
    },

    skipLoansOnProperty: function() {
      // add empty loan object if has other loans on this property
      if (
        this.section4LoanAndPropertyInformation.part4aLoanInformation
          .hasOtherLoansOnProperty
      )
        this.addLoanOnProperty();
      // clear all loans if has no other loans on this property
      else this.removeAllLoansOnProperty();
    },
    autoFillCityAndStateErrorDisplay: function(field, event, validations) {
      // return from function if zipcode is null
      if (field.zipCode === null) return;

      // call POST /customers method of URLA API
      this.getCityAndState(field.zip)
        .then((data) => {
          // return if data is empty
          if (Object.keys(data).length === 0 && data.constructor === Object) {
            field.city = '';
            field.state = '';
            field.county = '';
            this.setZipErorAfterApiResponse(event);
            validations.zip = 0;
            validations.city = 0;
            validations.state = 0;
            return;
          }
          // auto fill city
          field.city = data.City;
          // auto fill State
          field.state = data.State;
          // auto fill county if present
          if ("county" in field) field.county = data.County;
          validations.zip = 1;
          validations.city = 1;
          validations.state = 1;
        })
        .catch(() => {});
    },

    validatePurchasedOn: function(inputValue) {
      // min purchased on
      var min = new Date();
      min.setFullYear(min.getFullYear() - 100);

      // validate Date from validationsMixin and check range for date
      this.errors.part4aLoanInformation.purchasedOn = this.checkDateRangeFromValue(
        inputValue,
        this.getFormattedDate(min),
        this.currentDate
      );

      // set validation-icon
      this.validations.part4aLoanInformation.purchasedOn =
        this.errors.part4aLoanInformation.purchasedOn.length === 0 ? 1 : 0;

      // set investmentPropertySince
      if (
        this.validations.part4aLoanInformation.purchasedOn === 1 &&
        this.section4LoanAndPropertyInformation.part4aLoanInformation
          .investmentPropertySince === null
      )
        this.section4LoanAndPropertyInformation.part4aLoanInformation.investmentPropertySince = this.section4LoanAndPropertyInformation.part4aLoanInformation.purchasedOn;
    },

    validateInvestmentPropertySince: function(inputValue) {
      // set min value for range
      var min = new Date();
      min.setFullYear(min.getFullYear() - 100);

      // validate Date from validationsMixin and check range for date
      this.errors.part4aLoanInformation.investmentPropertySince = this.checkDateRangeFromValue(
        inputValue,
        this.getFormattedDate(min),
        this.currentDate
      );

      // set validation-icon
      this.validations.part4aLoanInformation.investmentPropertySince =
        this.errors.part4aLoanInformation.investmentPropertySince.length === 0
          ? 1
          : 0;
    },

    validateLoanAmount: function(event) {
      var errors = this.errors.part4aLoanInformation;
      var validations = this.validations.part4aLoanInformation;

      // validate amount address from validationsMixin
      errors.loanAmount = this.checkPrice(event);

      this.convertSalePrice =
        this.section4LoanAndPropertyInformation.part4aLoanInformation
          .propertyValue != null
          ? parseFloat(
              this.section4LoanAndPropertyInformation.part4aLoanInformation.propertyValue
                .replaceAll(",", "")
                .replace(",", "")
                .substring(1)
            )
          : null;
      this.convertLoanAmount = parseFloat(
        this.section4LoanAndPropertyInformation.part4aLoanInformation.loanAmount
          .replaceAll(",", "")
          .replace(",", "")
          .substring(1)
      );

      // set validation-icon
      validations.loanAmount =
        errors.loanAmount.length === 0 &&
        this.convertSalePrice != null &&
        this.convertLoanAmount <= 0.95 * this.convertSalePrice
          ? 1
          : 0;
    },

    validateStreet: function(event) {
      var errors = this.errors.part4aLoanInformation.propertyAddress;
      var validations = this.validations.part4aLoanInformation.propertyAddress;

      // validate street address from validationsMixin
      errors.street = this.checkStreet(event);

      // set validation-icon
      validations.street = errors.street.length === 0 ? 1 : 0;
    },

    validateUnit: function(event) {
      var errors = this.errors.part4aLoanInformation.propertyAddress;
      var validations = this.validations.part4aLoanInformation.propertyAddress;

      // validate unit address from validationsMixin
      errors.unit = this.checkUnit(event);

      // set validation-icon
      validations.unit = errors.unit.length === 0 ? 1 : 0;
    },

    validateCity: function(event) {
      var errors = this.errors.part4aLoanInformation.propertyAddress;
      var validations = this.validations.part4aLoanInformation.propertyAddress;

      // validate city name from validationsMixin
      errors.city = this.checkCity(event);

      // set validation-icon
      validations.city = errors.city.length === 0 ? 1 : 0;
    },

    validateCounty: function(event) {
      var errors = this.errors.part4aLoanInformation.propertyAddress;
      var validations = this.validations.part4aLoanInformation.propertyAddress;

      // validate county from validationsMixin
      errors.county = this.checkCounty(event);

      // set validation-icon
      validations.county = errors.county.length === 0 ? 1 : 0;
    },

    validateState: function(event) {
      var errors = this.errors.part4aLoanInformation.propertyAddress;
      var validations = this.validations.part4aLoanInformation.propertyAddress;

      // validate state name from validationsMixin
      errors.state = this.checkState(event);

      // set validation-icon
      validations.state = errors.state.length === 0 ? 1 : 0;
    },

    validateZip: function(event) {
      var errors = this.errors.part4aLoanInformation.propertyAddress;
      var validations = this.validations.part4aLoanInformation.propertyAddress;

      // validate zip from validationsMixin
      errors.zip = this.checkZip(event);
      var zipNoError = errors.zip.length === 0 ? 1 : 0;
      // get city name and State from Zip Code API
      if (zipNoError === 1){
        this.autoFillCityAndStateErrorDisplay(
          this.section4LoanAndPropertyInformation.part4aLoanInformation
            .propertyAddress,
           event, validations);
      }else {
      // set validation-icon
      validations.zip = errors.zip.length === 0 ? 1 : 0;
      }
    },

    validateNumberOfUnits: function(event) {
      var errors = this.errors.part4aLoanInformation.propertyAddress;
      var validations = this.validations.part4aLoanInformation.propertyAddress;

      // validate number from validationsMixin
      errors.numberOfUnits = this.checkNumber(event);

      // set validation-icon
      validations.numberOfUnits = errors.numberOfUnits.length === 0 ? 1 : 0;
    },

    validatePropertyValue: function(event) {
      var errors = this.errors.part4aLoanInformation;
      var validations = this.validations.part4aLoanInformation;

      // validate amount from validationsMixin
      errors.propertyValue = this.checkPrice(event);

      // set validation-icon
      validations.propertyValue = errors.propertyValue.length === 0 ? 1 : 0;
    },

    validatePreferredInterestRate: function(event) {
      var errors = this.errors.part4aLoanInformation;
      var validations = this.validations.part4aLoanInformation;

      // validate interest rate from validationsMixin
      errors.preferredInterestRate = this.checkInterestRate(event);

      // set validation-icon
      validations.preferredInterestRate =
        errors.preferredInterestRate.length === 0 ? 1 : 0;
    },

    setLoansOnProperty: function() {
      if (
        this.section4LoanAndPropertyInformation.part4aLoanInformation
          .loanPurpose === "Refinance" &&
        this.section4LoanAndPropertyInformation
          .part4bOtherLoansOnSubjectProperty.length === 0
      )
        this.addLoanOnProperty();
      else if (
        this.section4LoanAndPropertyInformation.part4aLoanInformation
          .loanPurpose === "Purchase" ||
        this.section4LoanAndPropertyInformation.part4aLoanInformation
          .loanPurpose === "Commercial Purchase for self use" ||
        this.section4LoanAndPropertyInformation.part4aLoanInformation
          .loanPurpose === "Commercial Purchase for Investment"
      )
        this.removeAllLoansOnProperty();
    },

    validateCreditorName(event, index) {
      var errors = this.errors.part4bOtherLoansOnSubjectProperty[index];
      var validations = this.validations.part4bOtherLoansOnSubjectProperty[
        index
      ];

      // validate amount from validationsMixin
      errors.creditorName = this.checkOrganizationName(event);

      // set validation-icon
      validations.creditorName = errors.creditorName.length === 0 ? 1 : 0;
    },

    validateMonthlyPayment(event, index) {
      var errors = this.errors.part4bOtherLoansOnSubjectProperty[index];
      var validations = this.validations.part4bOtherLoansOnSubjectProperty[
        index
      ];

      // validate amount from validationsMixin
      errors.monthlyPayment = this.checkPrice(event);

      // set validation-icon
      validations.monthlyPayment = errors.monthlyPayment.length === 0 ? 1 : 0;
    },

    validateOtherLoanAmount(event, index) {
      var errors = this.errors.part4bOtherLoansOnSubjectProperty[index];
      var validations = this.validations.part4bOtherLoansOnSubjectProperty[
        index
      ];

      // validate amount from validationsMixin
      errors.loanAmount = this.checkPrice(event);

      // set validation-icon
      validations.loanAmount = errors.loanAmount.length === 0 ? 1 : 0;
    },

    validateCreditLimit(event, index) {
      var errors = this.errors.part4bOtherLoansOnSubjectProperty[index];
      var validations = this.validations.part4bOtherLoansOnSubjectProperty[
        index
      ];

      // validate amount from validationsMixin
      errors.creditLimit = this.checkPriceAllowZero(event);

      // set validation-icon
      validations.creditLimit = errors.creditLimit.length === 0 ? 1 : 0;
    },

    validateExpectedMonthlyRentalIncome: function(event) {
      var errors = this.errors.part4cRentalIncomeOnSubjectProperty;
      var validations = this.validations.part4cRentalIncomeOnSubjectProperty;

      // validate amount address from validationsMixin
      errors.expectedMonthlyRentalIncome = this.checkPrice(event);

      // set validation-icon
      validations.expectedMonthlyRentalIncome =
        errors.expectedMonthlyRentalIncome.length === 0 ? 1 : 0;
    },

    goBack: function() {
      // emit event to go back with current page number
      bus.$emit("goBack", this.section4LoanAndPropertyInformation.currentPage);
    },

    submitSectionFour: function() {
      // validate form
      bus.$emit("postUrla", this.section4LoanAndPropertyInformation);
    },
    isPastDate(date) {
      const currentDate = new Date();
      return currentDate > date;
    },
  },
  mounted: function() {
    // set default values

    // default number of units
    if (
      this.section4LoanAndPropertyInformation.part4aLoanInformation
        .propertyAddress.numberOfUnits === null ||
      this.section4LoanAndPropertyInformation.part4aLoanInformation
        .propertyAddress.numberOfUnits === 0
    )
      this.section4LoanAndPropertyInformation.part4aLoanInformation.propertyAddress.numberOfUnits = 1;

    if (
      this.section4LoanAndPropertyInformation.part4aLoanInformation === null ||
      this.section4LoanAndPropertyInformation.part4aLoanInformation
        .preferredLoanProgram === null
    ) {
      this.section4LoanAndPropertyInformation.part4aLoanInformation.preferredLoanProgram =
        "30-Year Fixed";
    }

    // current Date
    this.currentDate = this.getToday();

    // scroll to top of page
    this.$scrollTo(this.$refs.sectionFour, {
      duration: 500,
      easing: "ease",
      offset: -200,
    });
  },

  created: function() {
    // copy data received from urla into current section
    this.section4LoanAndPropertyInformation = this.parseSection(
      this.section4LoanAndPropertyInformation,
      this.sourceSection4
    );

    // initialize validations object to null
    this.validations = this.initializeToNull(
      this.section4LoanAndPropertyInformation
    );

    // initialize errors object to empty array
    this.errors = this.initializeToEmptyArray(
      this.section4LoanAndPropertyInformation
    );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.preferred-interest-percentage {
  position: absolute;
  margin: auto;
  padding-top: 10px;
  margin-left: 100px;
}

.loan-purpose {
  display: flex;
  flex-direction: row;
}
.loan-purpose label {
  white-space: nowrap;
}
.loan-purpose:nth-child(2) {
  margin-top: 5px;
  margin-bottom: 15px;
}
.loan-purpose:nth-child(2) label{
  width: 200px !important;
  white-space: initial;
  padding-right: 0%;
  margin-right: 20px;
}
.question-styling {
  margin: 5px 0px;
}
</style>
